import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import DeviceNotEligibleIcon from "../../../images/icon-device-not-eligible.svg";
import { TextLight } from "../../../base/BaseText";
import BaseButton from "../../../base/BaseButton/BaseButton";

const DeviceUnderReview = () => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  const handleMoveToSmartSupport = () => {
    if (process.env.REACT_APP_IS_MYASURION_REDIRECT === "true") {
      window.location = process.env.REACT_APP_MY_ASURION_DP_REDIRECT_URL;
    } else {
      setDisabled(true);
      window.open(process.env.REACT_APP_SMART_SUPPORT_LINK);
    }
  };

  return (
    <BaseCard>
      <BaseIcon
        icon={DeviceNotEligibleIcon}
        name="Device Not Eligible Icon"
        center
      />
      <BaseLabel className="text-1.3">
        <Trans i18nKey="DeviceUnderReview.Title">
          <TextLight>
          Please note that your device registration is currently under review. 
          Please wait for the review process to complete.
          </TextLight>
        </Trans>
      </BaseLabel>
      <BaseButton
        text={t("DeviceNotEligible.MoveToBtn")}
        onClick={handleMoveToSmartSupport}
        disabled={disabled}
        clicked={disabled}
        className="px-6"
      />
    </BaseCard>
  );
};

export default DeviceUnderReview;
